<template>
  <vs-popup title="学员管理" :active.sync="show">
    <vs-table :data="clientsOfTrainer">
      <template slot="header">
        <h3>教练:{{trainer?getTrainerUserName(trainer.id):''}}</h3>
      </template>
      <template slot="thead">
        <vs-th>姓名</vs-th>
        <vs-th>移动电话</vs-th>
        <vs-th>状态</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="field0">{{getUserName(tr.user_id)}}</vs-td>
          <vs-td>{{getUserMobile(tr.user_id)}}</vs-td>
          <vs-td>{{tr.state=="active"?"活动":"归档"}}</vs-td>
          <vs-td>
            <vs-button v-if="tr.state=='active'" color="danger" icon="power_off" @click="archiveClient(tr)">归档</vs-button>
            <vs-button v-else color="success" icon="power" @click="activeClient(tr)">激活</vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vs-popup>
</template>

<script>
// import NewClient from "@/views/components/coach/client/NewClient.vue";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],
  props: ["trainer", "show"],

  data() {
    return {
      showBox: false
    };
  },

  watch: {
    show(newVal, oldVal) {
      if (newVal != oldVal) this.showBox = newVal;
    },

    showBox(newVal) {
      if (!newVal) {
        this.$emit("hide");
      }
    },

    trainer() {
      if (!this.trainer) return;

      this.$store.dispatch("getClients", { trainer_id: this.trainer.id });
    }
  },

  computed: {
    clientsOfTrainer() {
      let aryRtn = this.trainer
        ? this.clients.filter(item => item.trainer_id == this.trainer.id)
        : [];
      return aryRtn;
    },

    ...mapState(["organizations", "users", "trainers", "clients"])
  },

  methods: {
    addNewClient(trainer) {
      this.activeTrainer = trainer;
      this.showNewClient = true;
    },

    archiveClient(client) {
      this.$store.dispatch("archiveClient", client)
    },

    activeClient(client){
      this.$store.dispatch("activeClient", client)
    },

    deleteTrainer(trainer){
      this.$store.dispatch("deleteTrainer", trainer)
    }
  },

  components: {}
};
</script>

<style scoped>
.field0 {
  white-space: nowrap;
}
</style>
