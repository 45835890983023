<template>
  <vs-popup title="添加新教练" :active.sync="showBox">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <select v-model="selectedOrg" required class="orgs">
          <option disabled value="" selected>选择一个组织</option>
          <option v-for="(org, orgIdx) in organizations" :value="org.id" :key="orgIdx">{{org.name}}</option>
        </select>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-user"
          label-placeholder="姓名"
          v-model="user.name"
        />
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-smartphone"
          label-placeholder="移动电话"
          v-model="user.mobile"
        />
        <vs-input
          class="w-full"
          type="password"
          icon-pack="feather"
          icon="icon-lock"
          label-placeholder="密码"
          v-model="user.password"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="save">保存</vs-button>
        <vs-button
          color="warning"
          type="border"
          class="mb-2"
          @click="showBox=false"
        >取消</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";
import { mapState } from "vuex";

export default {
  mixins: [baseFun],

  props: ["organization_id", "show"],

  data() {
    return {
      showBox: this.show,

      trainer: {
        organization_id: 0,
        can_add_clients: false,
        is_organization_owner: false,
        super_admin: false,
        max_clients: 5
      },

      user:{
        name: null,
        mobile: null,
        password: null
      },

      selectedOrg: ''
    };
  },

  created(){
      this.trainer.organization_id = this.organization_id
  },

  computed: {
    ...mapState([
      "activeTrainer",
      "activeOrganization",
      "organizations"
    ])
  },

  watch: {
    show: function(newVal, oldVal) {
      if (newVal != oldVal) this.showBox = newVal;
    },
    showBox: function(newVal) {
      if (!newVal) {
        this.$emit("hide");
      }
    }
  },

  methods: {
    save() {
      if (this.selectedOrg && this.user.mobile && this.user.name) {
        this.trainer.organization_id = this.selectedOrg;
        this.$store.dispatch("createTrainer", { trainer: this.trainer, user: this.user, vm: this });
        this.$emit("hide");
      }
    },

    cancel() {
      /*user.mobile=user.name=''; user.type='in-person'; */
        this.$emit("hide");
    }
  }
};
</script>


<style scoped>
select.orgs {
    width: 100%;
    padding: 5px 0;
    font-family: "Microsoft YaHei", "微软雅黑", SimSun, "宋体", Heiti, "黑体", sans-serif;
    font-size: 1rem;
    border-radius: 5px;
    margin: 5px 0;
}
</style>
