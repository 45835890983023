<template>
  <div>
    <vs-row>
      <!-- <vs-col vs-w="10" vs-type="flex" vs-justify="flex-end">
        <vs-button
          color="primary"
          @click="addNewTrainer"
        >创建新教练</vs-button>
      </vs-col> -->
      <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end">
        <vs-button
          color="primary"
          @click="refresh"
        >刷新</vs-button>
      </vs-col>
    </vs-row>

    <vs-table search :data="tableTrainers" max-items="10" pagination>
      <template slot="header">
        <h3>教练</h3>
      </template>
      <template slot="thead">
        <vs-th sort-key="tr.trainerName">姓名</vs-th>
        <vs-th sort-key="tr.trainerMobile">移动电话</vs-th>
        <vs-th>组织</vs-th>
        <vs-th>学员数量</vs-th>
        <vs-th>最大学员数量</vs-th>
        <vs-th>删除教练</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].trainerName" class="field0">{{tr.trainerName}}</vs-td>
          <vs-td :data="data[indextr].trainerMobile" >{{tr.trainerMobile}}</vs-td>
          <vs-td>{{tr.organization?tr.organization.name:'-'}}</vs-td>
          <vs-td>{{tr.trainer.active_clients_count}}</vs-td>
          <vs-td>{{tr.trainer.max_clients}}</vs-td>
          <vs-td>
            <vs-button color="danger" siza="small" @click="deleteTrainer(tr)">删除</vs-button>
            <!-- <vs-dropdown>
              <span>
                更多...
                <vs-icon class icon="expand_more"></vs-icon>
              </span>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="addNewClient(tr)">添加学员</vs-dropdown-item>
                <vs-dropdown-item @click="editClients(tr)">学员维护</vs-dropdown-item>
                <vs-dropdown-item @click="deleteTrainer(tr)">删除该教练</vs-dropdown-item>
                <vs-dropdown-item @click="resetPassword(tr)">重置密码</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown> -->
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <NewClient
      :trainer_id="selectedTrainer?selectedTrainer.id:0"
      :show="showNewClient"
      :active="true"
      @hide="showNewClient=false"
    />
    <NewTrainer
      :organization_id="activeOrganization.id"
      :show="showNewTrainer"
      @hide="showNewTrainer=false"
    />
    <TrainerClients :trainer="selectedTrainer" :show="showClients" @hide="showClients=false" />
  </div>
</template>

<script>
import NewClient from "@/views/components/coach/client/NewClient.vue";
import NewTrainer from "@/views/components/coach/trainer/NewTrainer.vue";
import TrainerClients from "@/views/components/coach/client/TrainerClients.vue";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      showNewClient: false,
      showClients: false,
      showNewTrainer: false,

      selectedTrainer: null
    };
  },

  created() {
    this.$store.commit("adminPageIdx", 1);
    this.$store.dispatch("getOrganizations");
    this.$store.dispatch("getTrainers");
  },

  computed: {
    tableTrainers(){
      let ary = [];
      if (this.trainers) this.trainers.forEach(item => {
        if (!item) return;

        let newItem = {
          trainer:item,
          trainerUser:this.getUser(item.user_id),
          organization:null,
          trainerName: '',
          trainerMobile: '',
          organizationName: ''
          };
        if (newItem.trainerUser) {
          newItem.trainerName = newItem.trainerUser.name;
          newItem.trainerMobile = newItem.trainerUser.mobile;
        }
        if (item.organization_id) newItem.organization = this.getOrganization(item.organization_id);
        if (newItem.organization) newItem.organizationName = newItem.organization.name;

        ary.push(newItem);
      });

      ary.sort((a,b)=>a.trainer.organization_id==b.trainer.organization_id
      ?b.trainerUser.name-a.trainerUser.name
      :a.trainer.organization_id-b.trainer.organization_id);

      return ary;
    },

    ...mapState([
      "activeOrganization",
      "organizations",
      "users",
      "trainers",
      "clients"
    ])
  },

  methods: {

    addNewClient(trainer) {
      this.selectedTrainer = trainer;
      this.showNewClient = true;
    },

    editClients(trainer) {
      this.selectedTrainer = trainer;
      this.showClients = true;
    },

    // transferClient(client) {
    //   console.log(client.id);
    // },

    addNewTrainer() {
      this.showNewTrainer = true;
    },

    refresh(){
      this.$store.dispatch("getTrainers")
    },

    deleteTrainer(trainer){
      this.selectedTrainer = trainer;
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否删除"' + trainer.trainerUser.name + '"？ ',
        "accept-text": '确定',
        "cancel-text": '取消',
        accept:this.deleteTrainerProcess
      })
    },

    deleteTrainerProcess(){
      let trainer = this.selectedTrainer;
      if (!trainer) return;
      this.$store.dispatch("deleteTrainer", {trainer:trainer.trainer, user:trainer.trainerUser, vm: this})
    }
  },

  components: {
    NewClient,
    NewTrainer,
    TrainerClients
  }
};
</script>

<style scoped>
.field0 {
  white-space: nowrap;
}
</style>
